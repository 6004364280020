import React from "react"
import Sticky from "react-sticky-el"
import {graphql} from "gatsby"
import SEO from "../components/SEO/index"
import StyledBackgroundSection from "../components/BackgroundSection"
import HeroSection from "../components/Sections/Hero"
import SectionZero from "../components/Sections/Section"
import SectionOne from "../components/Sections/SectionOne"
import SectionTwo from "../components/Sections/SectionTwo"
import SectionThree from "../components/Sections/SectionThree"
import SectionFour from "../components/Sections/SectionFour"
import Contact from "../components/Sections/Contact"
import NavBar from "../components/navbar"
import localize from "../components/localize"

const IndexPage = (props) => {
    const contact = props.data.contacts.edges[0].node;
    const address = props.data.address.edges;
    return (
        <>
            <SEO
                title="Agro Export Alyans - Сушеные овощи и зелень Узбекистан, Сушеные овощи Ташкент, Сушеные овощи Фергана, Сушеные овощи купить Узбекистан, Сушеные овощи цена Узбекистан, Паприка молотая, Паприка, Острый перец, Острый перец хлопья, Укроп, Морковь, Свекла, Баклажан, Капуста, Петрушка, Шиповник, Солнечная паприка, Болгарский перец, Томаты, Яблоко, Зеленая паприка"
                description="Вкусные сушеные овощи из солнечного Узбекистана, Сушеные овощи и зелень Узбекистан, Сушеные овощи Ташкент, Сушеные овощи Фергана, Сушеные овощи купить Узбекистан, Сушеные овощи цена Узбекистан, Паприка молотая, Паприка, Острый перец, Острый перец хлопья, Укроп, Морковь, Свекла, Баклажан, Капуста, Петрушка, Шиповник, Солнечная паприка, Болгарский перец, Томаты, Яблоко, Зеленая паприка"
            />
            <Sticky className="sticky-selector" topOffset={150}>
                <div className="is-shadow">
                    <NavBar data={props.data.menu} />
                </div>
            </Sticky>
            <StyledBackgroundSection>
                <HeroSection
                    title={props.data.home.edges[0].node.hero}
                    btn={props.data.home.edges[0].node.hero_button} />
            </StyledBackgroundSection>
            <SectionZero
                product={props.data.product}
                products={props.data.products}
                title={props.data.home.edges[0].node.section_1}
                btn={props.data.home.edges[0].node.section_1_button}
                data={props.data.card} />
            <SectionThree
                title={props.data.home.edges[0].node.section_2}
                desc={props.data.home.edges[0].node.section_2_desc}
                list1={props.data.home.edges[0].node.section_2_list_1}
                list2={props.data.home.edges[0].node.section_2_list_2}
                list3={props.data.home.edges[0].node.section_2_list_3}
                list4={props.data.home.edges[0].node.section_2_list_4}
                list5={props.data.home.edges[0].node.section_2_list_5}
                list6={props.data.home.edges[0].node.section_2_list_6} />
            <SectionOne
                title={props.data.home.edges[0].node.section_3}
                btn={props.data.home.edges[0].node.section_3_button}
                desc={props.data.home.edges[0].node.section_3_desc}
                data={props.data.section}/>
            <SectionFour
                title={props.data.home.edges[0].node.section_4}
                list1={props.data.home.edges[0].node.section_4_list_1}
                list2={props.data.home.edges[0].node.section_4_list_2}
                list3={props.data.home.edges[0].node.section_4_list_3}
                list4={props.data.home.edges[0].node.section_4_list_4} />
            {/* <SectionTwo data={data.about} /> */}
            <Contact contact={contact} address={address} />
        </>
    )
}

export default localize(IndexPage);

export const IndexPageQuery = graphql`
  query {
      menu: allSanityMenu {
      edges {
        node {
           name {
             _type
             en
             ru
           }
         }
       }
     }
     product: allSanityProductpage {
        edges {
          node {
            section_button {
              _type
              en
              ru
            }
            section_button_2 {
              _type
              en
              ru
            }
          }
        }
      }
     products: allSanityProducts {
        edges {
          node {
            name {
              _type
              en
              ru
            }
            pathname
            image {
              asset {
                gatsbyImageData(width: 200)
              }
            }
          }
        }
      }
     contacts: allSanityContact {
        edges {
          node {
            copyright {
              en
              ru
              _type
            }
            phone {
              en
              ru
            }
            phone_numbers {
              _type
              en
              ru
            }
            social {
              _type
              en
              ru
            }
            textarea {
              _type
              en
              ru
            }
            our_contacts {
              _type
              en
              ru
            }
            name {
              _type
              en
              ru
            }
            address {
              _type
              en
              ru
            }
            button {
              _type
              en
              ru
            }
            copyright {
              _type
              en
              ru
            }
            email {
              _type
              en
              ru
            }
            email_title {
              _type
              en
              ru
            }
            leave_request {
              _type
              en
              ru
            }
          }
        }
      }
      home: allSanityHome {
        edges {
          node {
            hero {
              _type
              en
              ru
            }
            hero_button {
              _type
              en
              ru
            }
            section_1 {
              en
              ru
              _type
            }
            section_1_button {
              _type
              en
              ru
            }
            section_2 {
              _type
              en
              ru
            }
            section_2_desc {
              _type
              en
              ru
            }
            section_2_list_1 {
              _key
              _type
              en
              ru
            }
            section_2_list_2 {
              _type
              en
              ru
            }
            section_2_list_3 {
              _type
              en
              ru
            }
            section_2_list_4 {
              _type
              en
              ru
            }
            section_2_list_5 {
              _type
              en
              ru
            }
            section_2_list_6 {
              _type
              en
              ru
            }
            section_3 {
              _type
              en
              ru
            }
            section_3_button {
              _type
              en
              ru
            }
            section_3_desc {
              _type
              en
              ru
            }
            section_4 {
              _type
              en
              ru
            }
            section_4_list_1 {
              _type
              en
              ru
            }
            section_4_list_2 {
              _type
              en
              ru
            }
            section_4_list_3 {
              _type
              en
              ru
            }
            section_4_list_4 {
              _type
              en
              ru
            }
          }
        }
      }
      hero: allSanityHome {
        edges {
          node {
            hero {
              _type
              en
              ru
            }
          }
        }
      }
      address: allSanityAddress {
        edges {
          node {
            address {
              en
              ru
              _type
            }
          }
        }
      }
    section: allSectionYaml {
      edges {
        node {
          id
          featuredImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    card: allProductsYaml {
      edges {
        node {
          id
          title
          description
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 150)
            }
          }
        }
      }
    }
    about: allAboutYaml {
      edges {
        node {
          id
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 150)
            }
          }
        }
      }
    }
  }
`
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Columns, Column } from "../CompoundComponents/Columns"
import {
  Section,
  SectionTitle,
  SectionSubtitle,
} from "../CompoundComponents/Section"
import { Box } from "../CompoundComponents/Box"

const SectionThree = ({title, desc, list1, list2, list3, list4, list5, list6}) => {
  return (
    <Section white>
      <Columns tcentered centered>
        <Column is6 tmcentered>
          <SectionTitle>
            {title}
          </SectionTitle>
          <SectionSubtitle bottom>
            {desc}
          </SectionSubtitle>
        </Column>
      </Columns>
      <Columns tcentered centered>
        <Column is3321>
          <Box centered height>
            <span className="icon is-large radius-50 has-background-warning mb-1">
              <FontAwesomeIcon icon="industry" size="2x" color="#000" />
            </span>
            <p class="block has-family-pt-sans has-text-weight-semibold">
              {list1}
            </p>
          </Box>
        </Column>
        <Column is3321>
          <Box centered height>
            <span className="icon is-large radius-50 has-background-warning mb-1">
              <FontAwesomeIcon icon="globe-asia" size="2x" color="#000" />
            </span>
            <p class="block has-family-pt-sans has-text-weight-semibold">
              {list2}
            </p>
          </Box>
        </Column>
        <Column is3321>
          <Box centered height>
            <span className="icon is-large radius-50 has-background-warning mb-1">
              <FontAwesomeIcon icon="dollar-sign" size="2x" color="#000" />
            </span>
            <p class="block has-family-pt-sans has-text-weight-semibold">
              {list3}
            </p>
          </Box>
        </Column>
        <Column is3321>
          <Box centered height>
            <span className="icon is-large radius-50 has-background-warning mb-1">
              <FontAwesomeIcon icon="users-slash" size="2x" color="#000" />
            </span>
            <p class="block has-family-pt-sans has-text-weight-semibold">
              {list4}
            </p>
          </Box>
        </Column>
        <Column is3321>
          <Box centered height>
            <span className="icon is-large radius-50 has-background-warning mb-1">
              <FontAwesomeIcon icon="boxes" size="2x" color="#000" />
            </span>
            <p class="block has-family-pt-sans has-text-weight-semibold">
              {list5}
            </p>
          </Box>
        </Column>
        <Column is3321>
          <Box centered height>
            <span className="icon is-large radius-50 has-background-warning mb-1">
              <FontAwesomeIcon icon="handshake" size="2x" color="#000" />
            </span>
            <p class="block has-family-pt-sans has-text-weight-semibold">
              {list6}
            </p>
          </Box>
        </Column>
      </Columns>
    </Section>
  )
}

export default SectionThree

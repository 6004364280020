import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "../CompoundComponents/Button"
import { Columns, Column } from "../CompoundComponents/Columns"
import { Hero, HeroTitle, HeroBody } from "../CompoundComponents/Hero"

const HeroSection = ({title, btn}) => {
  return (
    <>
      <Hero>
        <HeroBody>
          <Columns vcentered>
            <Column is5>
              <HeroTitle>
                {title}
              </HeroTitle>
              <Link to="/products">
                <Button>
                  <span>
                    {btn}
                  </span>
                  <span className="icon is-small">
                    <FontAwesomeIcon icon="long-arrow-alt-right" color="#fff" />
                  </span>
                </Button>
              </Link>
            </Column>
          </Columns>
        </HeroBody>
      </Hero>
    </>
  )
}

export default HeroSection

import React, { Children } from "react"

export const HeroTitle = ({ children }) => (
  <h2 className="is-size-3 is-size-5-mobile has-text-white has-text-weight-light mb-1">
    {children}
  </h2>
)

export const HeroSubTitle = ({ children }) => (
  <p className="is-size-2 is-size-5-mobile has-text-white has-text-weight-light mb-2">
    {children}
  </p>
)

export const HeroImg = ({ children }) => (
  <figure className="image mb-2">{Children.toArray(children)}</figure>
)

export const HeroBody = ({ children }) => (
  <div className="hero-body has-bg-000">
    <div className="container">{children}</div>
  </div>
)

export const HeroHead = ({ children }) => (
  <div className="hero-head">{Children.toArray(children)}</div>
)

export const Hero = ({ children }) => {
  return (
    <section id="home" className="hero is-medium has-text-centered">
      {children}
    </section>
  )
}

import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Carousel from "react-multi-carousel"
import { ShoppingConsumer } from "../../layouts/index"
import { Button, Buttons } from "../CompoundComponents/Button"
import { Columns, Column } from "../CompoundComponents/Columns"
import {
  Card,
  CardContent,
  CardImage,
  CardContentTitle,
} from "../CompoundComponents/Card"
import { Section, SectionTitle } from "../CompoundComponents/Section"
import "react-multi-carousel/lib/styles.css"
import downloadFile from "../../images/production.pdf"

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}
const SectionZero = ({ title, btn, data, products, product }) => {
  console.log(products);
  console.log(product);
  return (
    <Section>
      <Columns vcentered centered>
        <Column is11>
          <SectionTitle tcentered>
            {title}
          </SectionTitle>
        </Column>
      </Columns>
      <ShoppingConsumer>
        {({ cart, handleAddToCart }) => (
          <Carousel
            responsive={responsive}
            autoPlay={true}
            infinite={true}
            ssr={false}
            showDots
          >
            {products.edges.map((item, i) => (
              <Card>
                <CardImage>
                  <GatsbyImage image={getImage(item.node.image.asset.gatsbyImageData)} />
                </CardImage>
                <CardContent>
                  <CardContentTitle>
                    {item.node.name}
                  </CardContentTitle>
                </CardContent>
                <footer className="card-footer">
                  {cart.filter(
                    e =>
                      e === item.node.name
                  ).length > 0 ? (
                    <div className="card-footer-item">
                      <Link to="/contact">
                        <Button>
                          <span>
                              Оформить
                          </span>
                        </Button>
                      </Link>
                    </div>
                  ) : (
                    <div className="card-footer-item">
                      <button
                        onClick={() => handleAddToCart(item.node.name)}
                        className="button hvr-bounce-to-right is-size-6-mobile is-success is-outlined has-text-weight-bold is-normal has-family-pt-sans"
                      >
                        {product.edges[0].node.section_button}
                      </button>
                    </div>
                  )}

                  <div class="card-footer-item">
                    <Link to={`/products/${item.node.pathname}`}>
                      <Button>
                        {product.edges[0].node.section_button_2}
                      </Button>
                    </Link>
                  </div>
                </footer>
              </Card>
            ))}
          </Carousel>
        )}
      </ShoppingConsumer>
      <div className="has-text-centered mt-2">
        <a
          className="button is-rounded is-normal is-success is-uppercase has-text-weight-bold"
          href={downloadFile}
          download
        >
          {btn}
        </a>
      </div>
    </Section>
  )
}

export default SectionZero

import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Columns, Column } from "../CompoundComponents/Columns"
import {
  Section,
  SectionTitle,
  SectionSubtitle,
} from "../CompoundComponents/Section"
import { Button } from "../CompoundComponents/Button"

const SectionOne = ({title, btn, desc, data }) => {
  const images = getImage(data.edges[0].node.featuredImage);
  console.log(images);
  return (
    <Section noPaddingBottom>
      <Columns vcentered>
        <Column is5 offset1 tmcentered>
          <SectionTitle>
            {title}
          </SectionTitle>

          <SectionSubtitle>
            {desc}
          </SectionSubtitle>

          <Link to="/contact">
            <Button success>
              {btn}
            </Button>
          </Link>
        </Column>
        <Column is5 offset1>
          <GatsbyImage image={images} />
        </Column>
      </Columns>
    </Section>
  )
}

export default SectionOne

import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Columns, Column } from "../CompoundComponents/Columns"
import { Section, SectionTitle } from "../CompoundComponents/Section"

const SectionFour = ({title, list3, list4, list2, list1}) => {
  return (
    <Section primary>
      <Columns vcentered tcentered centered>
        <Column is7 tmcentered>
          <SectionTitle bottom white>
            {title}
          </SectionTitle>
        </Column>
      </Columns>
      <ul className="steps has-content-centered">
        <li className="steps-segment">
          <span className="steps-marker has-background-warning">
            <FontAwesomeIcon icon="address-book" size="lg" color="#000" />
          </span>
          <div class="steps-content">
            <p className="has-text-weight-semibold has-text-white">
              {list1}
            </p>
          </div>
        </li>
        <li className="steps-segment">
          <span className="steps-marker has-background-warning">
            <FontAwesomeIcon icon="headset" size="lg" color="#000" />
          </span>
          <div class="steps-content">
            <p className="has-text-weight-semibold has-text-white">
              {list2}
            </p>
          </div>
        </li>
        <li className="steps-segment">
          <span className="steps-marker has-background-warning">
            <FontAwesomeIcon icon="shipping-fast" size="lg" color="#000" />
          </span>
          <div class="steps-content">
            <p className="has-text-weight-semibold has-text-white">
              {list3}
            </p>
          </div>
        </li>
        <li className="steps-segment">
          <span className="steps-marker has-background-warning">
            <FontAwesomeIcon icon="file-signature" size="lg" color="#000" />
          </span>
          <div class="steps-content">
            <p className="has-text-weight-semibold has-text-white">
              {list4}
            </p>
          </div>
        </li>
      </ul>
    </Section>
  )
}

export default SectionFour
